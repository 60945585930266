//LIBRARIES
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, Link, withRouter, RouteComponentProps } from "react-router-dom";
import ProLayout, { Settings, SettingDrawer } from '@ant-design/pro-layout';
import logo from '../../assets/images/logo.png';

import RightContent from "../GlobalHeader/RightContent"
//TYPINGS
import { IApplicationState } from "../../store/reducers";
import Footer from "../Footer";
import { WindowFocusHandler } from "../WindowFocusHandler";

 const permittedUsers = [
    'Ahmet Göze',
    'Berkay Kiran',
    'Farisha Hani',
    'Francis Fung',
    'Homam Hosseini',
    'Genesis Mallorca Obtera',
    'Justin Raymond Ventura',
    'Kuda Shamu',
    'Emmancipate Musemwa',
    'Syazalina Syazni',
    'Sabi Ridwan',
    'Niko Lee',
    'sabi',
    'ahmet',
    'berkay',
    'emman',
    'farisha',
    'francis',
    'homam',
    'genesis',
    'justin',
    'kuda',
    'niko',
    'syazalina'
  ];
const AuthenticatedRoute:React.FC<{component:React.FC<any>} & RouteComponentProps> = ({ component: Component, ...rest }) => {

  const isAuthenticated = useSelector((state:IApplicationState) => state.login.isAuthenticated);
  const [collapsed, handleMenuCollapse] = useState<boolean>(true);
  const [settings, setSettings] = useState<Partial<Settings>>({});
  const hasToken = window.location.href.includes("token");
  const username = localStorage.getItem("bupperUsername");
  const currentUserString = localStorage.getItem("currentUser") as any;
  const currentUser = JSON.parse(currentUserString) as any

  console.log('currentUser', currentUser)



  return(
    <Route
      {...rest}
      render={props =>
        (isAuthenticated || hasToken) ? (
          <>
          <WindowFocusHandler/>
          <ProLayout
            logo={logo}
            title="Ouisys Panel"
            collapsed={collapsed}
            onCollapse={handleMenuCollapse}
            footerRender={()=><Footer/>}
            menuItemRender={(menuItemProps, defaultDom) => {
              if (menuItemProps.isUrl) {
                return defaultDom;
              }
              return <Link onClick={()=>{
                if(menuItemProps.path === window.location.pathname){
                  setTimeout(() => {   
                    window.location.reload();
                  }, 0);
                }
              }}to={menuItemProps.path}>{defaultDom}</Link>;
            }}
            menuDataRender={(routers = []) => [
              {
                path: '/',
                name: "Dashboard",
                icon:'dashboard',
                exact: true
              },{
                path: '/hot-pages',
                name: "Hot Pages",
                icon:'fire',
                exact: true
              },{
                path: '/activity-log',
                name: "Activity Log",
                icon:'file-text',
                exact: true
              },
              {
                path: '/campaigns',
                name: "Campaigns",
                icon:'notification',
                exact: true,
                children: [
                  { 
                    path: '/campaigns/browse',
                    name: 'Browse',
                    icon: 'search',
                    hideInMenu:false,
                    exact: true

                  },
                  { 
                    path: '/campaigns/create',
                    name: 'Create By Multiple Affiliates',
                    icon: 'plus',
                    exact: true,
                  },
                  { 
                    path: '/campaigns/create-by-affiliate',
                    name: 'Create By Multiple Pages ',
                    icon: 'plus-square',
                    exact: true,
                  }
                ]
              },
              {
                path: '/pages',
                name: "Pages",
                icon:'file',
                exact: true,
                children: [
                  { 
                    path: '/pages/unpublished',
                    name: 'Unpublished',
                    icon: 'eye-invisible',
                    exact: true
                  },
                  { 
                    path: '/pages/published',
                    name: 'Published',
                    icon: 'eye',
                    exact: true
                  }
                 
                ]
              },
          ...currentUser && currentUser.username && permittedUsers.includes(currentUser.username) ? [{
                path: '/dynamic-pages',
                name: "Dynamic Pages",
                icon:'build',
                exact: true,
                children: [
                  { 
                    path: '/dynamic-pages/unpublished',
                    name: 'Unpublished',
                    icon: 'eye-invisible',
                    exact: true
                  },
                  { 
                    path: '/dynamic-pages/published',
                    name: 'Published',
                    icon: 'eye',
                    exact: true
                  },
                  // { 
                  //   path: '/dynamic-pages/manage',
                  //   name: 'Manage Page Configs',
                  //   icon: 'search',
                  //   exact: true
                  // },
                  { 
                    path: '/dynamic-pages/create',
                    name: 'Create Page',
                    icon: 'plus',
                    exact: true
                  },
                  { 
                    path: '/dynamic-pages/templates',
                    name: 'Templates',
                    icon: 'layout',
                    exact: true
                  },
                  { 
                    path: '/dynamic-pages/images',
                    name: 'Images',
                    icon: 'file-image',
                    exact: true
                  },
                  { 
                    path: '/dynamic-pages/mccs',
                    name: 'MCCs',
                    icon: 'bank',
                    exact: true
                  }
                ]
              }] : []
              ,
              {
                path: '/legals',
                name: "Legals",
                icon:'read',
                exact: true,
                children: [
                  { 
                    path: '/legals/browse',
                    name: 'Browse',
                    icon: 'search',
                    exact: true
                  },
                  { 
                    path: '/legals/create',
                    name: 'Create',
                    icon: 'plus',
                    exact: true
                  }
                ]
              },
              { 
                path: '/abtests',
                name: 'A/B Tests',
                icon: 'pause',
                exact: true,
                children: [
                  { 
                    path: '/abtests/history',
                    name: 'Activity',
                    icon: 'clock-circle',
                    exact: true
                  },
                  { 
                    path: '/abtests/create',
                    name: 'Create',
                    icon: 'plus',
                    exact: true
                  }
                ]
              },
              { 
                path: '/split-traffic',
                name: 'Split Traffic',
                icon: 'fullscreen',
                exact: true,
                children: [
                  { 
                    path: '/split-traffic/history',
                    name: 'Activity',
                    icon: 'clock-circle',
                    exact: true
                  },
                  { 
                    path: '/split-traffic/create',
                    name: 'Create',
                    icon: 'plus',
                    exact: true
                  }
                ]
              },
              {
                path: '/contents',
                name: "Contents",
                icon:'file-text',
                exact: true,
                children: [
                  { 
                    path: '/contents/browse',
                    name: 'Browse',
                    icon: 'search',
                    exact: true
                  },
                  { 
                    path: '/contents/create',
                    name: 'Create',
                    icon: 'plus',
                    exact: true
                  }
                ]
              },
              {
                path: '/configs',
                name: "Configs",
                icon:'setting',
                exact: true,
                children: [
                  /* { 
                    path: '/configs',
                    name: 'Single-Flow Scenarios',
                    icon: 'line',
                    exact: true,
                    children: [
                      { 
                        path: '/configs/scenarios/browse',
                        name: 'Browse',
                        icon: 'search',
                        exact: true
                      },
                      { 
                        path: '/configs/scenarios/create',
                        name: 'Create',
                        icon: 'plus',
                        exact: true
                      }
                    ]
                  }, */
                  { 
                    path: '/configs',
                    name: 'Strategies',
                    icon: 'fork',
                    exact: true,
                    children: [
                      { 
                        path: '/configs/strategies/browse',
                        name: 'Browse',
                        icon: 'search',
                        exact: true
                      },
                      { 
                        path: '/configs/strategies/create',
                        name: 'Create',
                        icon: 'plus',
                        exact: true
                      }
                    ]
                  }
                ]
              },
              {
                path: '/anti-fraud-rules',
                name: "Anti-Fraud Rules",
                icon:'safety',
                exact: true,
                children: [
                  { 
                    path: '/anti-fraud-rules/browse',
                    name: 'Browse',
                    icon: 'search',
                    exact: true
                  },
                  { 
                    path: '/anti-fraud-rules/create',
                    name: 'Create',
                    icon: 'plus',
                    exact: true
                  }
                ]
              },
              {
                path: '/analyse-domains',
                name: "Analyse Domains",
                icon:'global',
                exact: true
              }
            ]}
            rightContentRender={rightProps => <RightContent {...rightProps} />}   
          >
          <Component {...props} />
         </ProLayout>
         </>
        ) : (
          <Redirect to={`/login?redirect=${window.location.pathname + window.location.search}`} />
        )
      }
    />
  )
};
export default withRouter(AuthenticatedRoute);
